import getPermissionLabel from "../helpers";

export function generateCommand(rows, objects, reason, playerId) {
    if (!reason.trim() || !playerId.trim()) {
        return "Raison et Player ID requis pour générer les commandes.";
    }

    const commandsByRole = {};
    const refundAmounts = {};
    const refundAmountsSale = {};
    let totalRefund = 0;
    let totalRefundSale = 0;

    rows
        .filter((row) => row.selectedObject && row.quantity > 0)
        .forEach((row) => {
            const selectedObject = objects.find(
                (object) => object.id === row.selectedObject
            );

            if (selectedObject) {
                const role = getPermissionLabel(selectedObject.minGroupGive);

                if (!commandsByRole[role]) {
                    commandsByRole[role] = [];
                }

                if (
                    selectedObject.id.includes("job") ||
                    selectedObject.id.includes("Placeholder")
                ) {
                    const refundAmount = row.quantity * 8;
                    totalRefund += refundAmount;
                    refundAmounts[role] = (refundAmounts[role] || 0) + refundAmount;
                } else if (selectedObject.id.includes("money")) {
                    const refundAmount = row.quantity * 1;
                    totalRefund += refundAmount;
                    refundAmounts[role] = (refundAmounts[role] || 0) + refundAmount;
                } else if (selectedObject.id.includes("saleMoney")) {
                    const refundAmountSale = row.quantity * 1;
                    totalRefundSale += refundAmountSale;
                    refundAmountsSale[role] =
                        (refundAmountsSale[role] || 0) + refundAmountSale;
                } else {
                    commandsByRole[role].push(
                        `\`/giveItemOffline ${playerId} ${row.selectedObject} ${row.quantity} Ticket de remboursement : ${reason}\``
                    );
                }
            }
        });

    let justification = generateJustification(playerId, reason, totalRefund);
    let saleJustification = generateSaleJustification(
        playerId,
        reason,
        totalRefundSale
    );

    Object.entries(refundAmounts).forEach(([role, amount]) => {
        if (amount > 0) {
            commandsByRole[role].unshift(`\`/giveBankOffline ${playerId} ${amount}\``);
        }
    });

    Object.entries(refundAmountsSale).forEach(([role, amount]) => {
        if (amount > 0) {
            commandsByRole[role].unshift(`\`/giveItemOffline ${playerId} saleMoney ${amount}\``);
        }
    });

    const allCommands = Object.entries(commandsByRole)
        .map(([role, commands]) => `${role}:\n${commands.join("\n")}`)
        .join("\n\n");

    let returnString = `${allCommands}`;
    if (totalRefund > 0) returnString = `${returnString}\n\n${justification}`;
    if (totalRefundSale > 0) returnString = `${returnString}\n\n${saleJustification}`;

    return returnString || "Aucune ligne valide pour générer une commande.";
}

function generateJustification(playerId, reason, totalRefund) {
    return totalRefund > 0
        ? `__**Justification**__ (https://discord.com/channels/1060320282709069864/1269433211834994821)
\`\`\`ID Unique : ${playerId}
Montant : ${totalRefund} $
Raison : ${reason}
Staff ayant effectué la demande: \`\`\`
    ` : "";
}

function generateSaleJustification(playerId, reason, totalRefundSale) {
    return totalRefundSale > 0
        ? `__**Justification**__ (https://discord.com/channels/1060320282709069864/1269433211834994821)
\`\`\`ID Unique : ${playerId}
Montant : ${totalRefundSale} $
Raison : ${reason}
Staff ayant effectué la demande: \`\`\`
    ` : "";
}
