export const parseUnitXInput = (input, objects, rowCount) => {
    const regex = /(\d+)x\s+([a-zA-Z0-9_]+)/g;
    const newRows = [];
    let match;

    while ((match = regex.exec(input)) !== null) {
        const quantity = parseInt(match[1], 10);
        const id = match[2];

        const itemExists = objects.some((object) => object.id === id);

        if (itemExists) {
            newRows.push({
                id: rowCount + newRows.length + 1,
                selectedObject: id,
                quantity,
            });
        }
    }

    return newRows;
};

const getPermissionLabel = (permission) => {
    switch (permission) {
        case null:
            return "Staff";
        case "staff":
            return "Staff";
        case "supermodo":
            return "Staff";
        case "staff2":
            return "Gest. Modération";
        case "superadmin":
            return "Gest. Modération";
        case "owner":
            return "Aaron";
        case "dev":
            return "Développeur";
        case "gerantstaff":
            return "Responsable";
        default:
            return "Inconnu";
    }
};

export default getPermissionLabel;
