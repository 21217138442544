import React from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";

function Navbar() {
    return (
        <nav className="navbar">
            <Link to="/commands" className="nav-button">Commandes</Link>
            <Link to="/items" className="nav-button">Items</Link>
        </nav>
    );
}

export default Navbar;
