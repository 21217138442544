import React, {useState, useEffect} from "react";
import {parseUnitXInput} from "../utils/helpers";
import {generateCommand} from "../utils/Commands/CommandGenerator";
import TableSection from "./Commands/TableSection";
import "../styles/Commands.css";
import {useObjects} from "../hooks/useObjects";

function Commands() {
    const { objects, loading, error } = useObjects();
    const [rows, setRows] = useState([]);
    const [reason, setReason] = useState("");
    const [playerId, setPlayerId] = useState("");
    const [unitXInput, setUnitXInput] = useState("");

    useEffect(() => {
        if (!loading && objects.length > 0) {
            setRows((prevRows) =>
                prevRows.map((row) => {
                    if (row.selectedObject === "") {
                        return { ...row, selectedObject: objects[0].id };
                    }
                    return row;
                })
            );
        }
    }, [objects, loading]);

    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            {
                id: prevRows.length + 1,
                selectedObject: objects.length > 0 ? objects[0].id : "",
                quantity: 1
            }
        ]);
    };

    const removeRow = (rowId) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
    };

    const updateRow = (rowId, field, value) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === rowId ? { ...row, [field]: value } : row
            )
        );
    };

    const handleParseUnitXInput = () => {
        const newRows = parseUnitXInput(unitXInput, objects, rows.length);
        setRows((prevRows) => [...prevRows, ...newRows]);
        setUnitXInput("");
    };

    const copyToClipboard = () => {
        const commands = generateCommand(rows, objects, reason, playerId);

        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(commands).then(() => {
                alert("Commandes copiées dans le presse-papiers !");
            }).catch((err) => {
                alert("Erreur lors de la copie dans le presse-papiers. Vérifiez votre navigateur.");
                console.error(err);
            });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = commands;
            textArea.style.position = "fixed";
            textArea.style.opacity = "0";
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand("copy");
                alert("Commandes copiées dans le presse-papiers !");
            } catch (err) {
                alert("Impossible de copier dans le presse-papiers. Copiez manuellement le texte.");
                console.error(err);
            }
            document.body.removeChild(textArea);
        }
    };

    if (loading) {
        return <p>Chargement des objets...</p>;
    }

    if (error) {
        return <p>Erreur: {error}</p>;
    }

    return (
        <div className="commands-container">
            <div className="right-column">
                <div className="input-box">
                    <div className="input-row">
                        <input
                            type="text"
                            placeholder="Player ID"
                            value={playerId}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,6}$/.test(value)) {
                                    setPlayerId(value);
                                }
                            }}
                            className="player-input"
                        />
                        <input
                            type="text"
                            placeholder="Raison"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className="reason-input"
                        />
                    </div>
                </div>
                <div className="unitx-box">
                    <textarea
                        placeholder="Unité X (EX: Confiscation des armes: 1x weapon_crowbar, 1x weapon_golfclub)"
                        value={unitXInput}
                        onChange={(e) => setUnitXInput(e.target.value)}
                        className="unitx-input"
                        rows="3"
                    />
                    <button onClick={handleParseUnitXInput} className="unitx-button">
                        Ajouter depuis Unité X
                    </button>
                </div>
                <div className="command-box">
                    <textarea
                        readOnly
                        value={generateCommand(rows, objects, reason, playerId)}
                        placeholder="Les commandes générées apparaîtront ici."
                        className="commands-textarea"
                    />
                    <button onClick={copyToClipboard} className="copy-button">
                        Copier les commandes
                    </button>
                </div>
            </div>
            <div className="left-column">
                <div className="table-section">
                    <TableSection
                        rows={rows}
                        objects={objects}
                        addRow={addRow}
                        removeRow={removeRow}
                        updateRow={updateRow}
                    />
                </div>
            </div>
        </div>
    );
}

export default Commands;
