import React, {useState} from "react";
import "../styles/Items.css";
import getPermissionLabel from "../utils/helpers";
import {useObjects} from "../hooks/useObjects";

function Items() {
    const { objects, loading, error } = useObjects();
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState({
        staff: false,
        staff2: false,
        owner: false,
        dev: false,
        gerantstaff: false,
        supermodo: false,
        superadmin: false,
    });

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (event) => {
        const {name, checked} = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
    };

    const filteredItems = objects
        .filter((item) => {
            const name = item.name ? item.name.toLowerCase() : "";
            const id = item.id ? item.id.toLowerCase() : "";
            return name.includes(search.toLowerCase()) || id.includes(search.toLowerCase());
        })
        .filter((item) => {
            const label = getPermissionLabel(item.minGroupGive);
            const {staff, owner, dev, staff2, gerantstaff, superadmin, supermodo} = filters;
            const selectedFilters = [];
            if (staff) selectedFilters.push("Staff");
            if (supermodo) selectedFilters.push("Staff");
            if (staff2) selectedFilters.push("Gest. Modération");
            if (superadmin) selectedFilters.push("Gest. Modération");
            if (owner) selectedFilters.push("Aaron");
            if (dev) selectedFilters.push("Développeur");
            if (gerantstaff) selectedFilters.push("Responsable");
            if (selectedFilters.length === 0) return true;
            return selectedFilters.includes(label);
        });

    if (loading) {
        return <p>Chargement des objets...</p>;
    }

    if (error) {
        return <p>Erreur: {error}</p>;
    }

    return (
        <div className="items-container">
            <h1>Liste des Items</h1>
            <div className="filters-container">
                <input
                    type="text"
                    placeholder="Rechercher un item par nom ou ID..."
                    value={search}
                    onChange={handleSearch}
                    className="search-bar"
                />
                <div className="checkboxes">
                    <label>
                        <input
                            type="checkbox"
                            name="staff"
                            checked={filters.staff}
                            onChange={handleFilterChange}
                        />
                        <span>Staff</span>
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="staff2"
                            checked={filters.staff2}
                            onChange={handleFilterChange}
                        />
                        <span>Gest. Modération</span>
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="gerantstaff"
                            checked={filters.gerantstaff}
                            onChange={handleFilterChange}
                        />
                        <span>Responsable</span>
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="owner"
                            checked={filters.owner}
                            onChange={handleFilterChange}
                        />
                        <span>Aaron</span>
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="dev"
                            checked={filters.dev}
                            onChange={handleFilterChange}
                        />
                        <span>Pas de remboursement</span>
                    </label>
                </div>
            </div>
            <table className="styled-table">
                <thead>
                <tr>
                    <th>Item ID</th>
                    <th>Nom de l'Item</th>
                    <th>Permission</th>
                </tr>
                </thead>
                <tbody>
                {filteredItems.map((item) => (
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{getPermissionLabel(item.minGroupGive)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default Items;
