import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Navbar from "./components/Navbar";
import Commands from "./pages/Commands";
import Items from "./pages/Items";

function App() {
    // Alert de non-responsabilité
    alert(
        "Attention : Ce site fourni en tant qu'outil d'assistance et ne garantit pas l'exactitude des résultats générés. " +
        "L'utilisateur est entièrement responsable de vérifier toutes les informations et commandes avant de les utiliser ou de les appliquer. " +
        "Je décline toute responsabilité en cas d'erreurs ou de conséquences liées à une mauvaise utilisation de cet outil."
    );

    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path="/commands" element={<Commands/>}/>
                <Route path="/items" element={<Items/>}/>
            </Routes>
        </Router>
    );
}

export default App;
