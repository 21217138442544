import {useEffect, useState} from "react";
import {API_BASE_URL} from "../utils/utils";

export function useObjects() {
    const [objects, setObjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${API_BASE_URL}/api/v1/Objects`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch items");
                }
                return response.json();
            })
            .then((data) => {
                const sortedObjects = data.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                setObjects(sortedObjects);
                setLoading(false);
            })
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    }, []);

    return {objects, loading, error};
}
