import React from "react";
import Select from "react-select";

function TableSection({ rows, objects, addRow, removeRow, updateRow }) {
    return (
        <div>
            <table className="styled-table">
                <thead>
                <tr>
                    <th style={{ width: "50%" }}>Objet</th>
                    <th style={{ width: "30%" }}>Quantité</th>
                    <th style={{ width: "20%" }}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row) => (
                    <tr key={row.id}>
                        <td>
                            <Select
                                className="objects-list"
                                classNamePrefix="objects-select"
                                value={objects
                                    .map((obj) => ({
                                        label: obj.name,
                                        value: obj.id,
                                    }))
                                    .find((option) => option.value === row.selectedObject)}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={true}
                                name="objects"
                                placeholder={"Sélectionnez un objet"}
                                options={objects.map((obj) => ({
                                    label: obj.name,
                                    value: obj.id,
                                }))}
                                onChange={(selectedOption) =>
                                    updateRow(row.id, "selectedObject", selectedOption.value)
                                }
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                min="1"
                                value={row.quantity}
                                onChange={(e) =>
                                    updateRow(row.id, "quantity", e.target.value)
                                }
                                className="player-input"
                            />
                        </td>
                        <td>
                            <button
                                className="remove-button"
                                onClick={() => removeRow(row.id)}
                            >
                                -
                            </button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan="3" style={{ padding: "10px", textAlign: "center" }}>
                        <button
                            className="add-button-inline"
                            onClick={addRow}
                            title="Ajouter une ligne"
                            style={{ width: "100%" }}
                        >
                            +
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TableSection;
